import {useMutation, gql} from '@apollo/client';
import {MutationHookOptions} from '@apollo/react-hooks';

import {shiftsFields, editShiftFields} from './fields';
import {
  ShiftCreationData,
  CreateShiftVariables,
  UpdateShiftData,
  UpdateShiftVariables,
  SetShiftReviewData,
  SetShiftReviewVariables,
  SetInviteNdProToShiftData,
  SetInviteNdProToShiftVariables,
} from './types';
import {useApolloClients} from '../../../core/providers/ApolloContextProvider';

const createShift = (fields: string = shiftsFields) => gql`
    mutation CreateShift($createShift: CreateFacilityShiftsInput!){
        createShift(input: $createShift){
            ${fields}
        }
    }`;

export const useCreateShift = (
  options?: MutationHookOptions<ShiftCreationData, CreateShiftVariables>,
  fields?: string
) => {
  const {client} = useApolloClients();
  return useMutation<ShiftCreationData, CreateShiftVariables>(createShift(fields), {
    ...options,
    client,
  });
};

const updateShiftOpening = (fields: string = editShiftFields) => gql`
    mutation EditShift($updateShiftOpening: UpdateShiftDetailInput!){
        updateShiftOpening(input: $updateShiftOpening){
          ${fields}
        }
    }`;

export const useUpdateShiftOpening = (
  options?: MutationHookOptions<UpdateShiftData, UpdateShiftVariables>,
  fields?: string
) => {
  const {client} = useApolloClients();

  return useMutation<UpdateShiftData, UpdateShiftVariables>(updateShiftOpening(fields), {
    ...options,
    client,
  });
};

const setShiftReview = () => gql`
  mutation setShiftReview($shiftId: Int!, $review: TypeSetShiftReviewInput!, $relation: String!, $reason: String!) {
    setShiftReview(shiftId: $shiftId, review: $review, relation: $relation, reason: $reason) {
      id
    }
  }
`;

export const useSetShiftReview = (options?: MutationHookOptions<SetShiftReviewData, SetShiftReviewVariables>) => {
  const {legacyClient} = useApolloClients();

  return useMutation<SetShiftReviewData, SetShiftReviewVariables>(setShiftReview(), {
    ...options,
    client: legacyClient,
  });
};

const inviteNdProToShift = () => gql`
  mutation inviteNdProToShift($inviteShiftId: Int, $ndProId: Int, $bypassAutoSelect: Boolean, $inviteMessage: String) {
    inviteNdProToShift(
      inviteShiftId: $inviteShiftId
      ndProId: $ndProId
      bypassAutoSelect: $bypassAutoSelect
      inviteMessage: $inviteMessage
    ) {
      id
      facilityId
      status
      timeStart
      applicant {
        id
        nurse {
          id
          name
        }
        status
        channelId
        deletedAt
      }
    }
  }
`;

export const useInviteNdProToShift = (
  options?: MutationHookOptions<SetInviteNdProToShiftData, SetInviteNdProToShiftVariables>
) => {
  const {legacyClient} = useApolloClients();

  return useMutation<SetInviteNdProToShiftData, SetInviteNdProToShiftVariables>(inviteNdProToShift(), {
    ...options,
    client: legacyClient,
  });
};

export default {useCreateShift, useUpdateShiftOpening, useInviteNdProToShift};
