import MixpanelConstants from '../../constants';
import trackEvent from '../../events/trackEvent';

const trackShiftTemplatesListView = () => trackEvent(MixpanelConstants.SHIFT_TEMPLATES_VIEW);
const trackShiftTemplateCreateView = () => trackEvent(MixpanelConstants.CREATE_SHIFT_TEMPLATE_VIEW);
const trackTemplateView = (metadata: Record<string, any>, templateId: string) =>
  trackEvent(MixpanelConstants.SHIFT_TEMPLATE_VIEW, {metadata, templateId});
const trackShiftTemplateEditView = (metadata: Record<string, any>, templateId: string) =>
  trackEvent(MixpanelConstants.SHIFT_TEMPLATE_EDIT_VIEW, {metadata, templateId});

export {trackShiftTemplatesListView, trackShiftTemplateCreateView, trackTemplateView, trackShiftTemplateEditView};
