import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {Qualification} from '../types/qualifications';
import {Unit} from '../types/unit';
import {Openings} from '../types/openings';

export type ShiftTemplate = {
  id: string;
  facilityId: string;
  name: string;
  unitId: string;
  content: {
    unit: Unit;
    break?: string;
    endTime: string;
    openings: Openings[];
    positions: {
      id: string;
      name: string;
      adjustmentRate: number;
      rate: number;
      minBaseRate: number;
      maxBaseRate: number;
      shiftRate: number;
    }[];
    startTime: string;
    bonusAmount: number;
    description: string;
    qualifications: Qualification[];
    clockInInstruction: string;
    clockOutInstruction: string;
    positionIds: string[];
  };
  createdAt: string;
  createdBy: string;
  deletedAt: string;
  deletedBy: string;
  positionIds: string[];
  updatedAt: string;
  updatedBy: string;
  templateVersion?:string;
  __typename: string;
};

export type FacilityUnits = {
  getFacilityUnitPositionRate: {
    qualifications: {
      id: string;
      name: string;
    }[];
    units: {
      id: string;
      name: string;
      legacyUnitId: number;
      __typename: string;
      positions: {
        id: string;
        name: string;
        qualifications: {
          id: string;
          name: string;
          clockInInstruction: string;
          clockOutInstruction: string;
          description: string;
        }[];
        rate: {baseRate: number; subsidy: number};
      }[];
    }[];
  };
};

export type ShiftTemplateState = {
  data: any[];
  loading: boolean;
  error: string | null;
  shiftTemplate: ShiftTemplate | null;
  facilityUnits: FacilityUnits | null;
};

// Define the initial state using that type
const initialState: ShiftTemplateState = {
  data: [],
  loading: false,
  error: null,
  shiftTemplate: null,
  facilityUnits: null,
};

export const shiftTemplateSlice = createSlice({
  name: 'shiftTemplatesData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showTemplates: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    getShiftTemplates: state => {
      state.loading = true;
      state.error = null;
    },
    setShiftTemplates: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setShiftTemplatesError: (state, action: PayloadAction<any>) => {
      state.data = [];
      state.loading = false;
      state.error = action.payload;
    },
    getFacilityUnits: state => {
      state.loading = true;
      state.error = null;
    },
    setFacilityUnits: (state, action: PayloadAction<any>) => {
      state.facilityUnits = action.payload;
      state.loading = false;
      state.error = null;
    },
    setShiftTemplate: (state, action: PayloadAction<any>) => {
      state.shiftTemplate = action.payload;
    },

    newTemplateClicked: state => {
      state.shiftTemplate = null;
    },
    newTemplateClosed: state => {
      state.shiftTemplate = null;
    },
    createShiftTemplate: (state: ShiftTemplateState) => {
      state.loading = true;
      state.error = null;
    },
    updateShiftTemplate: (state: ShiftTemplateState) => {
      state.loading = true;
      state.error = null;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const {
  showTemplates,
  getShiftTemplates,
  getFacilityUnits,
  setShiftTemplates,
  setShiftTemplatesError,
  setShiftTemplate,
  newTemplateClicked,
  newTemplateClosed,
  createShiftTemplate,
  updateShiftTemplate,
  setFacilityUnits,
} = shiftTemplateSlice.actions;
export const selectShowTemplates = (state: RootState) => state.shiftTemplates;

export default shiftTemplateSlice.reducer;
