import MixpanelConstants from '../../constants';
import trackEvent from '../../events/trackEvent';

const trackMessagePageView = () => trackEvent(MixpanelConstants.MESSAGE_VIEW);
const trackMessageNdproPageView = () => trackEvent(MixpanelConstants.MESSAGE_NDPRO_VIEW);
const trackSettingsPageView = () => trackEvent(MixpanelConstants.SETTINGS_VIEW);
const trackHiringFormView = () => trackEvent(MixpanelConstants.HIRING_FORM);
const trackContactUsView = () => trackEvent(MixpanelConstants.CONTACT_US);

const trackMessageEvent = (data: Record<string, any>) => trackEvent(MixpanelConstants.MESSAGE_NDPRO, {data});
const trackUpdateSettingsEvent = (data: Record<string, any>) => trackEvent(MixpanelConstants.UPDATE_SETTINGS, {data});

export {
  trackSettingsPageView,
  trackHiringFormView,
  trackContactUsView,
  trackUpdateSettingsEvent,
  trackMessagePageView,
  trackMessageNdproPageView,
  trackMessageEvent,
};
