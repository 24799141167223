import React, {useEffect, useMemo, useState} from 'react';
// react-table
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
// icons
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import {RiDeleteBin5Line, RiLockPasswordLine} from 'react-icons/ri';
import {
  SearchIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  EditIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import {MdOutlineHistory} from 'react-icons/md';
// chakra-ui
import {
  Input,
  InputRightElement,
  InputGroup,
  Button,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Text,
  Select,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
// react-hook-form
import {useForm} from 'react-hook-form';
// types
import moment from 'moment';
import {AuthState} from '../../shared/slices/authSlice';
import {SidebarState} from '../../shared/slices/sidebarSlice';
import {ExampleState} from '../../shared/slices/testSlice';
import {UsersState, setUser} from '../../shared/slices/usersSlice';
import {ShiftTemplateState} from '../../shared/slices/shiftTemplateSlice';
import {User} from '../../shared/gql/Users/types';
// hooks
import useAppSelector from '../../shared/hooks/useAppSelector';
import useAppDispatch from '../../shared/hooks/useAppDispatch';
import useGetAllUsers from '../../shared/gql/Users/queries';

// columns
import useGetColumns from './usersColumns';
// components
import DeleteUserModal from './Overlay/DeleteUser/DeleteUserModal';
import InviteOrUpdateUser from './Overlay/InviteOrUpdateUser/InviteOrUpdateUser';
import Header from '../../shared/components/Header/Header';
import DisplayUser from './Overlay/DisplayUser/DisplayUser';
import UsersToast from './UsersToast/UsersToast';
import {trackUsersListPageView} from '../../shared/mixpanel/page-views';
import ChangePasswordModal from './Overlay/ChangePassword/ChangePassword';
import ActivityHistoryDrawer from '../../shared/components/ActivityHistory/ActivityHistoryDrawer';
import {useFacilityId} from '../Shifts/CreateShift/appSelectors';

const Users: React.FC = () => {
  const {register, watch, reset} = useForm();

  const users = useAppSelector(
    (state: {
      auth: AuthState;
      sidebar: SidebarState;
      shiftTemplates: ShiftTemplateState;
      example: ExampleState;
      usersData: UsersState;
    }) => state.usersData.users
  );

  const facilityId = useFacilityId();

  const [isInviteOrUpdateOpen, setIsInviteOrUpdateOpen] = React.useState(false);
  const [isDisplayUserModalOpen, setIsDisplayUserModalOpen] = React.useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = React.useState(false);
  const [isPassowrdModalOpen, setIsPassowrdModalOpen] = React.useState(false);
  const [isEditUser, setIsEditUser] = React.useState(false);
  const isInternalAdmin = useAppSelector(
    state => state?.auth?.decodedToken[`https://nursedash.com/custom`]?.canAssumeIdentity
  );
  const idFromStorage = localStorage.getItem('legacyFidParam');
  const canResetPassword = isInternalAdmin && idFromStorage;

  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector((state: {usersData: UsersState}) => state.usersData.user);

  const searchQuery = watch('search', '');

  const isNDEmail = (email: string) => email.toLowerCase().endsWith('@nursedash.com');
  const hideNDUsers = React.useMemo(
    () => (isInternalAdmin ? users : users.filter(userFilterd => !isNDEmail(userFilterd.email))),
    [isInternalAdmin, users]
  );
  const filteredUsers = React.useMemo(
    () =>
      searchQuery
        ? hideNDUsers.filter(user =>
            `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : hideNDUsers,
    [searchQuery, hideNDUsers]
  );

  const isDateWithinLastSevenDays = (
    dateString: string | null | undefined,
    sevenDaysAgo: moment.Moment,
    now: moment.Moment
  ): boolean => {
    if (!dateString) return false;
    const date = moment(dateString);
    return date.isBetween(sevenDaysAgo, now, 'day', '[]');
  };

  const getFilteredDeletedUsers = (userData: User[]) => {
    const now = moment();
    const sevenDaysAgo = moment().subtract(7, 'days');

    return [...userData]?.filter(user => {
      if (!user.deletedAt) return true;
      return (
        isDateWithinLastSevenDays(user?.lastLoginWeb, sevenDaysAgo, now) ||
        isDateWithinLastSevenDays(user?.lastActiveWeb, sevenDaysAgo, now)
      );
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterDeletedUser = useMemo(() => getFilteredDeletedUsers(filteredUsers), [filteredUsers]);

  const toast = useToast();

  const displayToast = (description: string, toastStatus: 'loading' | 'error' | 'info' | 'warning' | 'success') => {
    toast({
      position: 'top',
      duration: 5000,
      render: ({onClose}) => <UsersToast toastStatus={toastStatus} description={description} onClose={onClose} />,
    });
  };

  const handleCloseModal = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setState(false);
  };

  const [selectedData, setSelectedData] = React.useState<User | null>(null);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: isInternalAdmin ? filteredUsers : filterDeletedUser,
    columns: useGetColumns(),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    trackUsersListPageView();
  }, []);

  const handleRowClick = (row: User) => {
    setSelectedData(row);
    setIsDisplayUserModalOpen(true);
  };

  const appendApostrophe = (name: string) => {
    if (!name.endsWith('s')) {
      return `${name}'s`;
    }
    return `${name}'`;
  };

  const fullName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
  const apostropheName = appendApostrophe(fullName);

  useEffect(() => {
    reset({
      search: '',
    });
  }, [users, reset]);
  const {refetch, refetchAfterDelete} = useGetAllUsers();

  const rowModel = table.getRowModel();

  const {isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose} = useDisclosure();
  const [resourceData, setResourceData] = useState<{resourceId: string; resourceType: string; filter?: object}>({
    resourceId: '',
    resourceType: '',
  });

  return (
    <Flex flexDirection="column" padding={['inherit', '0rem .5rem']}>
      {isDisplayUserModalOpen && selectedData && (
        <DisplayUser
          user={`${selectedData.firstName} ${selectedData.lastName}`}
          email={selectedData.email}
          facility={selectedData.facilityName}
          userRole={selectedData.role}
          handleIsDisplayUserModalOpen={() => handleCloseModal(setIsDisplayUserModalOpen)}
        />
      )}
      {isDeleteUserModalOpen && (
        <DeleteUserModal
          title="Delete user"
          body={`Are you sure you want to remove ${apostropheName} access to ${selectedUser?.facilityName}?`}
          primaryBtnText="Yes, Delete"
          secondaryBtnText="No, Cancel"
          primaryBtnVariant="danger"
          isFlagged
          withCloseBtn
          handleIsModalOpen={() => handleCloseModal(setIsDeleteUserModalOpen)}
          refetchUsers={refetchAfterDelete}
        />
      )}
      {isPassowrdModalOpen && (
        <ChangePasswordModal
          title="Change Password"
          body={`Please enter the new password for ${fullName}`}
          primaryBtnText="Save"
          secondaryBtnText="Cancel"
          primaryBtnVariant="primary"
          displayToast={displayToast}
          handleIsModalOpen={() => handleCloseModal(setIsPassowrdModalOpen)}
          refetchUsers={refetch}
        />
      )}
      {isInviteOrUpdateOpen && (
        <InviteOrUpdateUser
          title={isEditUser ? 'Edit user' : 'Invite user'}
          body={isEditUser ? 'Update the profile for this user' : 'Invite a new user to access your Facility'}
          primaryBtnText={isEditUser ? 'Update' : 'Invite'}
          secondaryBtnText="Cancel"
          primaryBtnVariant="primary"
          handleIsModalOpen={() => handleCloseModal(setIsInviteOrUpdateOpen)}
          displayToast={displayToast}
          isEditUser={isEditUser}
          setIsEditUser={setIsEditUser}
          refetchUsers={refetch}
        />
      )}
      <Flex>
        <Header
          title="Users"
          onClick={() => setIsInviteOrUpdateOpen(true)}
          btnText="Invite user"
          isHistoryIcon
          onHistoryDrawerOpen={() => {
            setResourceData({
              resourceId: facilityId,
              resourceType: 'FACILITY',
              filter: {type: ['USER_CREATED', 'USER_UPDATED', 'USER_DELETED']},
            });
            onHistoryDrawerOpen();
          }}
          isInternalAdmin={isInternalAdmin}
        />
      </Flex>
      {/* Search and Filter Section */}
      <Flex
        justifyContent={['space-between', 'flex-start']}
        marginBottom="1rem"
        gap={['1rem', '1rem']}
        alignItems="flex-end"
      >
        <InputGroup w={['100%', '50%']}>
          <Input {...register('search')} placeholder="Search users" />
          <InputRightElement>
            <SearchIcon color="#718096" display={searchQuery ? 'none' : 'inherit'} />
            {searchQuery && (
              <IconButton
                size="xsm"
                color="#718096"
                variant="ghost"
                icon={<CloseIcon boxSize=".8rem" />}
                onClick={() => reset({search: ''})}
                aria-label="Clear"
              />
            )}
          </InputRightElement>
        </InputGroup>
      </Flex>
      <TableContainer border="1px solid rgba(0, 0, 0, 0.08)" borderRadius="12px" marginBottom="1rem">
        <Table
          marginTop="1.3rem"
          variant="ndTable"
          color="#2D3748"
          {...{
            users,
            columns: useGetColumns(),
          }}
        >
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th
                    backgroundColor="white"
                    color="#4A5568"
                    fontSize="12px"
                    paddingBottom="0.7rem"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    display={
                      header.column.columnDef.id === 'email' || header.column.columnDef.id === 'role'
                        ? ['none', 'revert']
                        : 'revert'
                    }
                    _hover={{
                      cursor: 'pointer',
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <Button
                      variant="unstyled"
                      color="#7C86B1"
                      type="button"
                      h="1.1rem"
                      padding="0 0.8rem 0.1rem 0"
                      bgColor="white"
                    />
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {rowModel?.rows?.length > 0 ? (
              rowModel.rows.map(row => (
                <Tr
                  key={row.id}
                  height="3.5rem"
                  _hover={{
                    cursor: 'pointer',
                  }}
                  bg={row?.original?.deletedAt !== null && isInternalAdmin ? '#f7b7b7b3 !important' : ''}
                >
                  {row.getVisibleCells().map(cell => (
                    <Td
                      maxWidth={['.1rem', 'inherit']}
                      width={['.1rem', 'inherit']}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      fontSize="14px"
                      key={cell.id}
                      display={
                        cell.column.columnDef.id === 'email' || cell.column.columnDef.id === 'role'
                          ? ['none', 'revert']
                          : 'revert'
                      }
                      onClick={() => {
                        handleRowClick(row.original);
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                  {/* Actions Column */}
                  <Td
                    onClick={() => {
                      dispatch(setUser(row.original));
                    }}
                  >
                    <Popover size="sm">
                      <PopoverTrigger>
                        <IconButton aria-label="Edit" icon={<BiDotsHorizontalRounded />} variant="filter" size="sm" />
                      </PopoverTrigger>
                      <PopoverContent width="100%">
                        <PopoverBody padding="0rem">
                          <Flex
                            _hover={{
                              backgroundColor: 'gray.200',
                              cursor: 'pointer',
                            }}
                            width="100%"
                            gap=".5rem"
                            padding="1rem"
                            alignItems="center"
                            onClick={() => {
                              setIsInviteOrUpdateOpen(true);
                              setIsEditUser(true);
                            }}
                          >
                            <EditIcon />
                            <Text>Edit User</Text>
                          </Flex>
                          {canResetPassword && (
                            <Flex
                              _hover={{
                                backgroundColor: 'gray.200',
                                cursor: 'pointer',
                              }}
                              width="100%"
                              gap=".5rem"
                              padding="1rem"
                              alignItems="center"
                              onClick={() => {
                                setIsPassowrdModalOpen(true);
                              }}
                            >
                              <RiLockPasswordLine />
                              <Text>Change Password</Text>
                            </Flex>
                          )}
                          <Flex
                            _hover={{
                              backgroundColor: 'gray.200',
                              cursor: 'pointer',
                            }}
                            width="100%"
                            gap=".5rem"
                            padding="1rem"
                            alignItems="center"
                            onClick={() => {
                              setIsDeleteUserModalOpen(true);
                            }}
                          >
                            <RiDeleteBin5Line />
                            <Text>Remove Access</Text>
                          </Flex>
                          {isInternalAdmin && (
                            <Flex
                              _hover={{
                                backgroundColor: 'gray.200',
                                cursor: 'pointer',
                              }}
                              width="100%"
                              gap=".5rem"
                              padding="1rem"
                              alignItems="center"
                              onClick={() => {
                                setResourceData({
                                  resourceId: row?.original?.userId,
                                  resourceType: 'USER',
                                });
                                onHistoryDrawerOpen();
                              }}
                            >
                              <MdOutlineHistory />
                              <Text>View History</Text>
                            </Flex>
                          )}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>
                  {/* ) : ( */}
                  {/* <Td> </Td> */}
                  {/* )} */}
                </Tr>
              ))
            ) : (
              <Tr height="3.5rem" width="100%">
                <Td colSpan={isInternalAdmin ? 7 : 4} textAlign="center">
                  <Text textAlign="center">No data available</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Flex justifyContent="space-between" wrap="wrap">
        <Flex gap="2rem">
          <Flex alignItems="center" gap=".5rem" display={['none', 'initial']}>
            <IconButton
              icon={<ArrowLeftIcon boxSize=".5rem" />}
              variant="pagination"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              _hover={{
                cursor: 'pointer',
              }}
              aria-label="Double arrow left"
            />
          </Flex>
          <Flex alignItems="center" gap=".5rem">
            <IconButton
              icon={<ArrowBackIcon boxSize=".8rem" />}
              variant="pagination"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              _hover={{
                cursor: 'pointer',
              }}
              aria-label="Arrow back"
            />
            <Text color="gray.700" display={['none', 'initial']}>
              Previous page
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Text marginRight=".2rem">Page </Text>
          <Text fontWeight="500">
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </Text>
        </Flex>
        <Flex gap="2rem">
          <Flex alignItems="center" gap=".5rem">
            <Text color="gray.700" display={['none', 'initial']}>
              Next page
            </Text>
            <IconButton
              icon={<ArrowForwardIcon boxSize=".8rem" />}
              padding="0.7rem"
              variant="pagination"
              onClick={() => {
                if (!table.getCanNextPage()) {
                  return;
                }
                table.nextPage();
              }}
              disabled={table.getState().pagination.pageIndex + 1 >= table.getPageCount() - 1}
              _hover={{
                cursor: 'pointer',
              }}
              aria-label="Arrow forward"
            />
          </Flex>
          <Flex alignItems="center" gap=".5rem" display={['none', 'initial']}>
            <IconButton
              icon={<ArrowRightIcon boxSize=".5rem" />}
              padding="0.9rem"
              variant="pagination"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={table.getState().pagination.pageIndex >= table.getPageCount() - 1}
              _hover={{
                cursor: 'pointer',
              }}
              aria-label="Double arrow forward"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%" marginTop="2rem" marginBottom="1rem">
        <InputGroup>
          <Text>
            Go to page:
            <Input
              width="3rem"
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                if (page >= 0 && page < table.getPageCount()) {
                  table.setPageIndex(page);
                }
              }}
              marginLeft=".5rem"
            />
          </Text>
        </InputGroup>
        <Select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
          display={['none', 'initial']}
          width="12rem"
          _hover={{
            cursor: 'pointer',
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize} rows
            </option>
          ))}
        </Select>
      </Flex>

      {resourceData?.resourceId && (
        <ActivityHistoryDrawer
          isOpen={isHistoryDrawerOpen}
          onClose={onHistoryDrawerClose}
          resourceId={resourceData?.resourceId}
          resourceType={resourceData?.resourceType}
          filter={resourceData?.filter}
        />
      )}
    </Flex>
  );
};

export default Users;
