import React, {useEffect} from 'react';

import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  useToast,
} from '@chakra-ui/react';

import {WarningTwoIcon} from '@chakra-ui/icons';

import {useDeleteUser} from '../../../../shared/gql/Users/mutations';

import {Props} from './DeleteUserModal.types';
import {UsersState} from '../../../../shared/slices/usersSlice';

import useAppSelector from '../../../../shared/hooks/useAppSelector';

import UsersToast from '../../UsersToast/UsersToast';
import useFacilityIdFromToken from '../../utils/tokenUtils';
import {trackDeleteUserEvent} from '../../../../shared/mixpanel/events/users';

const Modal = ({
  title,
  body,
  primaryBtnText,
  secondaryBtnText,
  primaryBtnVariant,
  isFlagged,
  withCloseBtn,
  handleIsModalOpen,
  refetchUsers,
}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const facilityIdFromDecodedToken = useFacilityIdFromToken();

  const selectedUser = useAppSelector((state: {usersData: UsersState}) => state.usersData.user);

  const [deleteUserFn] = useDeleteUser(
    {
      variables: {
        deleteUser: {
          userId: selectedUser?.userId ?? '',
          facilityId: facilityIdFromDecodedToken ?? '',
        },
      },
    },
    undefined,
    refetchUsers
  );

  const toast = useToast();

  const displayToast = (description: string, toastStatus: 'loading' | 'error' | 'info' | 'warning' | 'success') => {
    toast({
      position: 'top',
      duration: 5000,
      render: () => <UsersToast description={description} toastStatus={toastStatus} onClose={onClose} />,
    });
  };

  const handleDeleteUser = async () => {
    try {
      const deleteUserResponse = await deleteUserFn();
      if (deleteUserResponse.data) {
        displayToast('Access removed', 'success');
        onClose();
        handleIsModalOpen();
        trackDeleteUserEvent(selectedUser?.userId ?? '', {
          userId: selectedUser?.userId ?? '',
          facilityId: facilityIdFromDecodedToken ?? '',
        });
      } else if (!deleteUserResponse.data) {
        displayToast('Deletion failed', 'error');
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  // useEffect(() => {
  //   trackUserPageDelete();
  // }, []);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        handleIsModalOpen();
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="700" w="98%">
          {title}
        </ModalHeader>
        {withCloseBtn && <ModalCloseButton onClick={() => handleIsModalOpen()} />}
        {!isFlagged && <ModalBody>{body}</ModalBody>}
        {isFlagged && (
          <ModalBody
            display="flex"
            alignSelf="baseline"
            alignItems="center"
            border="2px solid #C53030"
            borderRadius="6px"
            marginLeft="1.5rem"
            width="85%"
            paddingLeft=".7rem"
          >
            <WarningTwoIcon marginRight=".7rem" boxSize={5} color="#C53030" />
            <Box>{body}</Box>
          </ModalBody>
        )}

        <ModalFooter>
          <Button
            variant="secondary"
            mr={3}
            onClick={() => {
              onClose();
              handleIsModalOpen();
            }}
          >
            {secondaryBtnText}
          </Button>
          <Button variant={primaryBtnVariant} onClick={() => handleDeleteUser()}>
            {primaryBtnText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
