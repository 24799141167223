import {Flex, Icon, Text, Tooltip} from '@chakra-ui/react';
import React from 'react';
import {FaHeart} from 'react-icons/fa';
import formatTimeWithZone from '../../../Messages/helpers/helpers';

const FormatNdProName = (shift: any, timeZone: string, isInternalAdmin: boolean = false) => {
  const formatName = (name: string, isFavorite: boolean, openingDetails: any) => (
    <Flex alignItems="center" gap="0.3rem" justifyContent="flex-start">
      <Text as="span" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" maxWidth="12rem">
        {name}
      </Text>{' '}
      {isFavorite && <Icon as={FaHeart} boxSize="14px" color="#FC8181" />}
      {openingDetails?.clockInTime && openingDetails?.clockOuTime && (
        <Tooltip
          label={
            isInternalAdmin
              ? `ClockIn: ${openingDetails?.clockInsupervisorName} | ${openingDetails?.clockInSupervisorEmail} | ${openingDetails?.clockInSupervisorPhone} 
                ClockOut: ${openingDetails?.clockOutsupervisorName} | ${openingDetails?.clockOutSupervisorEmail} | ${openingDetails?.clockOutSupervisorPhone}`
              : ''
          }
          placement="bottom"
        >
          <Text fontSize="10px" fontWeight="500" cursor="pointer">
            ( {formatTimeWithZone(openingDetails?.clockInTime, 'h:mma', timeZone)} -{' '}
            {formatTimeWithZone(openingDetails?.clockOuTime, 'h:mma', timeZone)} )
          </Text>
        </Tooltip>
      )}
    </Flex>
  );

  const ndProList = shift?.openings
    ?.filter((e: {status: string}) =>
      ['Confirmed', 'PendingReview', 'Completed', 'PendingCheckIn', 'PendingClockIn'].includes(e?.status)
    )
    .map((e: {ndProName: string; favorite: boolean}) => e?.ndProName && formatName(e?.ndProName, e?.favorite, e));

  return (
    <Flex flexDirection="column">
      {ndProList?.map((ndPro: string) => (
        <Text>{ndPro}</Text>
      ))}
    </Flex>
  );
};

export default FormatNdProName;
