const shiftTemplateFields = `
    id
    facilityId
    name
    unitId
    content
    positionIds
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    templateVersion
`;

export const facilityUnitPositionRateFields = `
qualifications{
  id
  name
}
units{
    id
    name
    legacyUnitId
    positions{
      id
      name
      rate{
        baseRate
        subsidy
        minBaseRate
        maxBaseRate
        margin
      }
      qualifications{
        id
        name
        description
        clockInInstruction
        clockOutInstruction
      }
    }
  }
`;

export const activityHistoryFields = `
  items{ 
    resourceId
    activityDate
    type
    resourceType
    title
    description
    rawData
    created{
      by
      date
      id
      ip 
      deviceId
      clientVersion
    }
  }
  nextLastEvaluatedKey
  count
`;

export default shiftTemplateFields;
