import React from 'react';

import {Flex, Text} from '@chakra-ui/react';
import {Controller} from 'react-hook-form';
import NDReactSelect from '../NDReactSelect/NDReactSelect';
import {SingleValue} from '../../../routes/ShiftTemplates/ShiftTemplates.types';
import UnitSkeleton from './UnitSkeleton/UnitSkeleton';
import {Props} from './UnitSelector.types';

const UnitSelector = ({
  control,
  register,
  shiftTemplate,
  isEditable,
  handleUnitSelection,
  errors,
  unitOptions,
  loading,
  isFromCreateShift,
}: Props) => {
  if (loading) {
    return <UnitSkeleton />;
  }

  return (
    <Flex flexDirection="column" gap=".5rem" w={['inherit', '26rem']}>
      <Text fontWeight="600">Unit</Text>
      <Controller
        name="unit"
        control={control}
        render={({field}) => (
          <NDReactSelect
            {...register('unit')}
            {...field}
            isMulti={false}
            placeholder="Unit"
            isDisabled={(!!shiftTemplate && !isEditable) || (isFromCreateShift && shiftTemplate?.unitId)}
            options={unitOptions}
            onChange={(e: SingleValue) => {
              handleUnitSelection(e);
            }}
          />
        )}
      />
      {errors?.unit && (
        <Text color="red.500" fontSize="sm">
          Unit required.
        </Text>
      )}
      <Text color="#718096" marginBottom="1.5rem">
        Choose the unit where the shift is needed
      </Text>
    </Flex>
  );
};

export default UnitSelector;
