import trackEvent from './trackEvent';
import MixpanelConstants from '../constants';

const trackInviteUserEvent = (data: Record<string, string>) => trackEvent(MixpanelConstants.INVITE_USER_ACTION, {data});
const trackEditUserEvent = (userId: string, data: Record<string, any>) =>
  trackEvent(MixpanelConstants.EDIT_USER, {userId, data});
const trackDeleteUserEvent = (userId: string, data: Record<string, any>) =>
  trackEvent(MixpanelConstants.DELETE_USER, {userId, data});
const trackChangeUserPasswordEvent = (userId: string) =>
  trackEvent(MixpanelConstants.CHANGE_PASSWORD, {userId});

// const trackEditUserClickEvent = (userId: string) => trackEvent(MixpanelConstants.USER_EDIT_INITIATED, {userId});
// const trackDeleteUserClickEvent = (userId: string) => trackEvent(MixpanelConstants.USER_DELETE_INITIATED, {userId});
// const trackChangeUserPasswordClickEvent = (userId: string) =>
//   trackEvent(MixpanelConstants.USER_CHANGE_PASSWORD_INITIATED, {userId});

export {
  trackInviteUserEvent,
  trackEditUserEvent,
  trackDeleteUserEvent,
  trackChangeUserPasswordEvent,
  // trackEditUserClickEvent,
  // trackDeleteUserClickEvent,
  // trackChangeUserPasswordClickEvent,
};
