const commonFacilityFields = `
  id
  name
  legacyFacilityId
`;

export const getFacilityFields = `
${commonFacilityFields}
  homeMarketName
  segmentName
  selection
  timeZone
  selection
`;
export default getFacilityFields;
