enum MixpanelConstants {
  LOGIN_VIEW = '/login',
  USER_LOGIN = 'LOGIN', // didn't add because it's a auth0 page and event
  USER_LOGOUT = 'LOGOUT',
  CALENDAR_VIEW_WEEKLY = '/calendar?view=weekly',
  CREATE_SHIFT_VIEW = '/shifts/create',
  ERROR_VIEW = '/error',
  CALENDAR_PRINT_VIEW_WEEKLY = '/weekly/print',

  SHIFT_EDIT_VIEW = '/edit-shift/:shiftId/:openingId',
  POST_SHIFT = 'SHIFT:CREATE',
  VIEW_CREATED_SHIFT = 'SHIFT:CREATED:VIEW', // implementation remaining
  PRINT_WEEK = 'WEEK:PRINT',
  UNIT_FILTER_CHANGE = 'WEEK:FILTER:UNIT',
  STATUS_FILTER_CHANGE = 'WEEK:FILTER:STATUS',
  SHIFT_DRAWER_VIEW = '/weekly/:shiftId',
  NDPRO_PROFILE_VIEW = 'NDPRO:PROFILE',
  SELECT_NDPRO_FROM_PROFILE = 'NDPRO:SELECT:PROFILE',
  ARCHIVE_NDPRO_FROM_PROFILE = 'NDPRO:ARCHIVE:PROFILE',
  UNARCHIVE_NDPRO_FROM_PROFILE = 'NDPRO:UNARCHIVE:PROFILE',
  MESSAGE_NDPRO_FROM_PROFILE = 'NDPRO:MESSAGE:PROFILE',
  FAVORITE_NDPRO_FROM_PROFILE = 'NDPRO:FAVORITE:PROFILE',
  UNFAVORITE_NDPRO_FROM_PROFILE = 'NDPRO:UNFAVORITE:PROFILE',
  FAVORITE_NDPRO = 'NDPRO:FAVORITE',
  UNFAVORITE_NDPRO = 'NDPRO:UNFAVORITE',
  DNR_NDPRO = 'NDPRO:ADD:DNR',
  REMOVE_DNR_NDPRO = 'NDPRO:REMOVE:DNR',
  NDPRO_RESUME_VIEW = 'NDPRO:RESUME:VIEW',
  SELECT_NDPRO = 'NDPRO:SELECT',
  UNSELECT_NDPRO = 'NDPRO:UNSELECT',
  ARCHIVE_NDPRO = 'NDPRO:ARCHIVE',
  UNARCHIVE_NDPRO = 'NDPRO:UNARCHIVE',
  EDIT_SHIFT = 'SHIFT:EDIT',
  CANCEL_SHIFT = 'SHIFT:CANCEL',
  RATE_NDPRO = 'NDPRO:RATE',

  CALENDAR_VIEW_MONTHLY_OVERVIEW = '/calendar?view=monthly-overview',
  CALENDAR_VIEW_MONTHLY_CONFIRMED = '/calendar?view=monthly-confirmed',
  AGENDA_VIEW_DRAWER_MONTHLY_OVERVIEW = '/monthly-overview/agenda-view',
  AGENDA_VIEW_DRAWER_MONTHLY_CONFIRMED = '/monthly-confirmed/agenda-view',
  DRAWER_UNIT_FILTER = 'MONTHLY:FILTER:UNIT',

  MESSAGE_VIEW = '/messages',
  MESSAGE_NDPRO_VIEW = '/messages/:channelId',
  MESSAGE_NDPRO = 'NDPRO:MESSAGE',

  SHIFT_TEMPLATES_VIEW = '/shift-templates',
  SHIFT_TEMPLATE_VIEW = '/shift-template/:id',
  SHIFT_TEMPLATE_EDIT_VIEW = '/shift-template/edit/:id',
  CREATE_SHIFT_TEMPLATE_VIEW = '/shift-template/create',
  CREATE_SHIFT_TEMPLATE = 'SHIFT_TEMPLATE:CREATE',
  UPDATE_SHIFT_TEMPLATE = 'SHIFT_TEMPLATE:UPDATE',
  DELETE_SHIFT_TEMPLATE = 'SHIFT_TEMPLATE:DELETE',
  SHIFT_TEMPLATE_SEARCH_FILTER = 'SHIFT_TEMPLATE:FILTER:SEARCH',
  SHIFT_TEMPLATE_UNIT_FILTER = 'SHIFT_TEMPLATE:FILTER:UNIT',
  SHIFT_TEMPLATE_POSITION_FILTER = 'SHIFT_TEMPLATE:FILTER:POSITION',

  USERS_VIEW = '/users',
  INVITE_USER = '/users/invite',
  USER_VIEW = '/users/:id',
  INVITE_USER_ACTION = 'USER:INVITE',
  EDIT_USER = 'USER:EDIT',
  DELETE_USER = 'USER:DELETE',
  CHANGE_PASSWORD = 'USER:CHANGE_PASSWORD',

  SETTINGS_VIEW = '/settings',
  UPDATE_SETTINGS = 'SETTINGS:UPDATE',

  HIRING_FORM = '/centralize-your-staff',
  CONTACT_US = 'CONTACT_US',
}

export default MixpanelConstants;
