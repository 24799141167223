import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import './RedirectToAuth0Button.scss';
import {Button} from '@chakra-ui/react';
// import {trackUserLoginEvent} from '../../../shared/mixpanel/events/login';
import {trackLoginPageView} from '../../../shared/mixpanel/page-views/pages/login';

const RedirectToAuth0Button: React.FC = () => {
  const urlParams = localStorage.getItem('legacyFidParam');
  const ndAdminUserId = localStorage.getItem('legacyNDAdminUserId');

  const {loginWithRedirect} = useAuth0();
  return (
    <Button
      className="sign-up-btn"
      bg="#313871"
      color="#ffffff"
      borderRadius="2px"
      type="button"
      width="100%"
      onClick={() => {
        trackLoginPageView({
          legacyFidParam: urlParams,
          ndAdminUserId,
          redirect_uri: `${window.location.origin}/home`,
        });
        loginWithRedirect({
          authorizationParams: {
            legacyFidParam: urlParams,
            ndAdminUserId,
            redirect_uri: `${window.location.origin}/home?source=auth0`,
          },
        });
      }}
    >
      Continue
    </Button>
  );
};

export default RedirectToAuth0Button;
