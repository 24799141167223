import {ChevronDownIcon} from '@chakra-ui/icons';
import {Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Switch, Text, Tooltip} from '@chakra-ui/react';
import React, {FC, useEffect, useState} from 'react';
import {UnitPosition} from '../Shift.types';
import {trackUnitFilterChangeEvent} from '../../../shared/mixpanel/events/calender';

interface IShiftUnitProps {
  shiftsSummary: UnitPosition[];
  handleSelectedUnitFilter: (value: {unitId: string; positions: string[]}[]) => void;
  selectedUnitFilter: {unitId: string; positions: string[]}[];
  filteredShiftCount?: string | number;
  totalShiftCount?: string | number;
  shifts?: any;
  isFromMonthlyDrawer?: boolean;
}

const ShiftUnitFilter: FC<IShiftUnitProps> = ({
  shiftsSummary = [],
  filteredShiftCount,
  totalShiftCount,
  selectedUnitFilter = [],
  handleSelectedUnitFilter,
  shifts,
  isFromMonthlyDrawer,
}) => {
  const [isOpen, setIsOpen] = useState('');
  const handleClose = () => {
    setIsOpen('');
  };

  const handleSelectAllUnit = () => {
    handleSelectedUnitFilter(
      shiftsSummary?.map(unit => ({
        unitId: unit.id,
        positions: unit.positions.map(position => position.name),
      }))
    );
  };

  const formatUnitName = () => {
    const formattedUnitName = selectedUnitFilter?.map(unit => {
      const unitDetails = shiftsSummary?.find(summary => summary.id === unit.unitId);
      const nameParts = unitDetails?.name?.split(' ');

      if (nameParts && nameParts?.length >= 2) {
        return `${nameParts?.[0].charAt(0).toUpperCase()}${nameParts?.[1].charAt(0).toUpperCase()}`;
      }
      return nameParts?.[0].charAt(0).toUpperCase();
    });

    if (formattedUnitName?.length) {
      return `- ${formattedUnitName?.slice(0, 2)?.join()} ${
        formattedUnitName?.length > 2 ? `+${Number(formattedUnitName?.length) - 2}` : ''
      }`;
    }
    return '';
  };

  const toggleUnitSelection = (unitId: string) => {
    const unit = shiftsSummary?.find(summary => summary.id === unitId);
    const unitPositionNames = unit?.positions?.map(position => position.name) || [];

    const selectedUnit = selectedUnitFilter?.find(u => u.unitId === unitId);

    if (
      selectedUnit &&
      selectedUnit?.positions?.length === shiftsSummary?.find(u => u.id === unitId)?.positions?.length
    ) {
      handleSelectedUnitFilter([...selectedUnitFilter].filter(u => u.unitId !== unitId));
    } else {
      const updatedSelection = [...selectedUnitFilter].find(u => u.unitId === unitId)
        ? selectedUnitFilter.map(u => (u.unitId === unitId ? {...u, positions: unitPositionNames} : u))
        : [...selectedUnitFilter, {unitId, positions: unitPositionNames}];

      handleSelectedUnitFilter(updatedSelection);
    }
  };

  const togglePositionSelection = (unitId: string, positionName: string) => {
    const unit = selectedUnitFilter.find(u => u.unitId === unitId);

    if (unit) {
      if (unit.positions.includes(positionName)) {
        const updatedPositions = unit.positions.filter(posName => posName !== positionName);

        if (updatedPositions.length === 0) {
          handleSelectedUnitFilter([...selectedUnitFilter].filter(u => u.unitId !== unitId));
        } else {
          handleSelectedUnitFilter(
            selectedUnitFilter.map(u => (u.unitId === unitId ? {...u, positions: updatedPositions} : u))
          );
        }
      } else {
        handleSelectedUnitFilter(
          selectedUnitFilter.map(u => (u.unitId === unitId ? {...u, positions: [...u.positions, positionName]} : u))
        );
      }
    } else {
      handleSelectedUnitFilter([...selectedUnitFilter, {unitId, positions: [positionName]}]);
    }
  };

  const isAllFiltersSelected = shiftsSummary?.every(unit => {
    const unitFromFilter = selectedUnitFilter?.find(selectedUnit => selectedUnit.unitId === unit.id);
    if (unitFromFilter && unit?.positions?.map(e => e?.name)?.every(name => unitFromFilter?.positions?.includes(name))) {
      return true;
    }
    return false;
  });

  const ShiftsSummaryWithCount = shiftsSummary?.map(unit => ({
    ...unit,
    unitCount: 0, // Initialize unit count
    positions: unit.positions.map(p => ({
      ...p,
      count: 0, // Initialize position count
    })),
  }));

  if (!isFromMonthlyDrawer) {
    shifts?.forEach((day: {shifts: any[]}) => {
      day?.shifts?.forEach((shift: {unitId: any; positionDetail: any[]}) => {
        const unit = ShiftsSummaryWithCount?.find(u => u.id === shift.unitId);
        if (unit) {
          unit.unitCount += 1; // Increment the unit count
          shift?.positionDetail?.forEach((unitPosition: any) => {
            const positionCounter = unit?.positions?.find(p => p.name === unitPosition?.name);
            if (positionCounter) {
              positionCounter.count += 1; // Increment the position count
            }
          });
        }
      });
    });
  } else {
    shifts?.forEach((shift: {unitId: any; positionDetail: any[]}) => {
      const unit = ShiftsSummaryWithCount?.find(u => u.id === shift.unitId);
      if (unit) {
        unit.unitCount += 1; // Increment the unit count
        shift?.positionDetail?.forEach((unitPosition: any) => {
          const positionCounter = unit?.positions?.find(p => p.name === unitPosition?.name);
          if (positionCounter) {
            positionCounter.count += 1; // Increment the position count
          }
        });
      }
    });
  }

  useEffect(() => {
    trackUnitFilterChangeEvent(selectedUnitFilter);
  }, [selectedUnitFilter]);

  return (
    <Menu closeOnSelect={false} isOpen={isOpen === 'unitMenu'} onClose={handleClose} autoSelect={false}>
      <Tooltip
        label={`Based on the selected filters, we are displaying ${filteredShiftCount} of ${totalShiftCount} shifts posted for selected day.`}
        placement="bottom"
        hasArrow
        maxWidth="15rem"
      >
        <MenuButton
          px={3}
          py={1}
          transition="all 0.2s"
          borderRadius="30px"
          borderWidth="0.0625rem"
          fontWeight="600"
          onClick={() => setIsOpen(prev => (prev === 'unitMenu' ? '' : 'unitMenu'))}
          height="fit-content"
          fontSize="0.8rem"
          borderColor={!isAllFiltersSelected ? '#FC8181' : '#00000029'}
          background={!isAllFiltersSelected ? '#FFF5F5' : 'white'}
        >
          Shift Units <span style={{color: '#3377DC'}}>{formatUnitName()}</span>
          <ChevronDownIcon ml="2" />
        </MenuButton>
      </Tooltip>

      <MenuList>
        <MenuGroup>
          <MenuItem _hover={{background: 'transparent'}}>
            <Flex width="100%" alignItems="center" justifyContent="space-between">
              <Text fontWeight="600" fontSize="0.8rem">
                Shift Units
              </Text>
              {!isAllFiltersSelected && (
                <Text
                  fontWeight="600"
                  color="#3065CA"
                  textDecoration="underline"
                  fontSize="0.8rem"
                  onClick={() => {
                    handleSelectAllUnit();
                  }}
                >
                  Reset
                </Text>
              )}
            </Flex>
          </MenuItem>

          {ShiftsSummaryWithCount?.slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shiftSummary: UnitPosition) => {
              const selectedUnit = selectedUnitFilter?.find(u => u.unitId === shiftSummary?.id);

              const isUnitSelected = selectedUnit?.unitId
                ? selectedUnit?.positions?.length ===
                  ShiftsSummaryWithCount?.find(u => u.id === shiftSummary?.id)?.positions?.length
                : false || !!selectedUnit?.unitId;

              return (
                <MenuItem _hover={{background: 'transparent'}} key={shiftSummary.id}>
                  <Flex width="100%" justifyContent="space-between" gap="0.5rem" flexDirection="column">
                    <Flex alignItems="center" gap="0.5rem">
                      <Switch
                        key={shiftSummary?.id}
                        alignItems="center"
                        gap="0.5rem"
                        onChange={e => {
                          e?.stopPropagation();
                          // handleSelectUnit(shiftSummary?.id);
                          toggleUnitSelection(shiftSummary?.id);
                        }}
                        isChecked={isUnitSelected}
                      />

                      <Text fontSize="0.8rem">
                        {shiftSummary?.name} ({shiftSummary?.unitCount || 0})
                      </Text>
                    </Flex>

                    {shiftSummary?.positions?.map(position => (
                      <Flex alignItems="center" gap="0.5rem" paddingLeft="2.3rem">
                        <Switch
                          key={`${shiftSummary?.id}-${position?.id}`}
                          alignItems="center"
                          gap="0.5rem"
                          onChange={e => {
                            e?.stopPropagation();
                            togglePositionSelection(shiftSummary?.id, position?.name);
                          }}
                          isChecked={selectedUnit?.unitId ? selectedUnit?.positions?.includes(position?.name) : false}
                        />

                        <Text fontSize="0.8rem">
                          {position?.name} ({position?.count || 0})
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </MenuItem>
              );
            })}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default ShiftUnitFilter;
