import trackEvent from './trackEvent';
import MixpanelConstants from '../constants';

// from profile
const trackNdproFavEventFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.FAVORITE_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackNdproRemoveFavEventFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNFAVORITE_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackNdproSelectFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.SELECT_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackArchiveApplicantFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.ARCHIVE_NDPRO_FROM_PROFILE, {metadata, nurseId});
const trackUnArchiveApplicantFromProfile = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNARCHIVE_NDPRO_FROM_PROFILE, {metadata, nurseId});

const trackNdproFavEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.FAVORITE_NDPRO, {metadata, nurseId});
const trackNdproRemoveFavEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNFAVORITE_NDPRO, {metadata, nurseId});
const trackNdproSelectEvent = (metadata: Record<string, any>, shiftId?: number, applicantId?: number) =>
  trackEvent(MixpanelConstants.SELECT_NDPRO, {shiftId, metadata, applicantId});
const trackNdproUnselectEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNSELECT_NDPRO, {metadata, nurseId});
const trackArchiveApplicantEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.ARCHIVE_NDPRO, {metadata, nurseId});
const trackUnArchiveApplicantEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.UNARCHIVE_NDPRO, {metadata, nurseId});
const trackNdproAddtoDNREvent = (nurseId: number, metadata?: any) =>
  trackEvent(MixpanelConstants.DNR_NDPRO, {metadata, nurseId});
const trackNdproRemoveFromDNREvent = (nurseId: number, metadata?: any) =>
  trackEvent(MixpanelConstants.REMOVE_DNR_NDPRO, {metadata, nurseId});

const trackNdproResumeViewEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.NDPRO_RESUME_VIEW, {metadata, nurseId});
const trackNdproRateEvent = (metadata: any, nurseId: number) =>
  trackEvent(MixpanelConstants.RATE_NDPRO, {metadata, nurseId});

export {
  trackNdproRemoveFavEventFromProfile,
  trackNdproFavEventFromProfile,
  trackNdproSelectFromProfile,
  trackUnArchiveApplicantFromProfile,
  trackArchiveApplicantFromProfile,
  trackNdproFavEvent,
  trackNdproRemoveFavEvent,
  trackNdproSelectEvent,
  trackNdproUnselectEvent,
  trackArchiveApplicantEvent,
  trackUnArchiveApplicantEvent,
  trackNdproAddtoDNREvent,
  trackNdproRemoveFromDNREvent,
  trackNdproResumeViewEvent,
  trackNdproRateEvent,
};
