import React from 'react';

import {FlexProps, Flex, Button, Icon} from '@chakra-ui/react';
import {IconType} from 'react-icons';

interface ContactUsProps extends FlexProps {
  icon: IconType;
  children: any;
  onClick: any;
}

const ContactUs = ({icon, children, onClick, ...rest}: ContactUsProps) => (
  <Flex
    align="center"
    p="4"
    margin="0 10px"
    borderRadius="lg"
    role="group"
    cursor="pointer"
    _hover={{
      textDecoration: 'underline',
    }}
    _active={{
      backgroundColor: '#475392',
    }}
    {...rest}
  >
    <Button
      // display={{base: 'flex', md: 'none'}}
      onClick={onClick}
      fontWeight="400"
      variant="link"
      colorScheme="white"
      color="white"
      leftIcon={
        <Icon
          mr="2"
          fontSize="16"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      }
    >
      {children}
    </Button>
  </Flex>
);

export default ContactUs;
