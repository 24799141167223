import moment from 'moment';
import {UnitPosition} from '../../Shift.types';

const processShifts = (shift: any) => {
  const processedShift = shift.shifts.map((innerShift: {openings: any}) => {
    const numOfCompleted =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'Completed')?.length || 0;
    const numOfConfirmed =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'Confirmed')?.length || 0;
    const numOfPendingReview =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'PendingReview')?.length || 0;
    const numOfPendingCheckIn =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'PendingCheckIn')?.length || 0;
    const numOfPendingClockIn =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status === 'PendingClockIn')?.length || 0;
    const posted =
      innerShift?.openings?.filter((opening: {status: string}) => opening?.status !== 'Cancelled')?.length || 0;
    const confirmed = numOfConfirmed + numOfCompleted + numOfPendingReview + numOfPendingCheckIn + numOfPendingClockIn;
    return {...innerShift, posted, confirmed};
  });
  return {...shift, shifts: processedShift};
};

const checkShiftStatus = (shift: any, timeZone: string) => {
  if (!shift?.openings?.length) {
    return 'cancelled';
  }
  if (shift?.openings?.every((opening: {status: string}) => opening.status === 'Cancelled')) return 'cancelled';

  const allShiftOpenings = shift?.openings ? [...shift.openings] : [];

  const nonCancelledOpenings = allShiftOpenings?.filter((opening: {status: string}) => opening.status !== 'Cancelled');
  // status : 'Filled Completely'
  if (
    nonCancelledOpenings?.every((opening: {status: string}) =>
      ['Completed', 'Confirmed', 'PendingReview', 'PendingCheckIn', 'PendingClockIn'].includes(opening.status)
    )
  )
    return 'filledCompletely';

  // status : 'Action Required'

  const pastEndTime = moment().isAfter(moment.tz(shift?.endTime, timeZone));

  if (
    !pastEndTime &&
    shift?.openings?.some((opening: {status: string}) => ['OpenedWithApplicants'].includes(opening.status))
    // (pastEndTime && shift.openings.some((opening: {status: string}) => ['PendingReview'].includes(opening.status)))
  )
    return 'actionRequired';

  // status : 'Partially Filled'

  if (
    shift?.openings?.some((opening: {status: string}) =>
      ['Completed', 'PendingReview', 'Confirmed', 'PendingCheckIn', 'PendingClockIn'].includes(opening.status)
    )
  )
    return 'filledPartially';

  // status : 'Cancelled'

  if (pastEndTime) return 'pastUnfilled';

  return 'upcomingUnfilled';
};

const renderShiftStatusText = (shift: any) => {
  if (shift?.openings?.length) {
    const isAllCancelled = shift?.openings?.every((opening: {status: string}) => opening.status === 'Cancelled');

    const pendingReviewCount = shift.openings?.filter(
      (opening: {status: string}) => opening.status === 'PendingReview'
    ).length;
    const allOpenedWithApplicants = shift.openings?.every(
      (opening: {status: string}) => opening.status === 'OpenedWithApplicants'
    );

    // Filter out cancelled openings before counting filled and total openings
    const nonCancelledOpenings =
      shift?.openings && [...shift.openings]?.filter((opening: {status: string}) => opening.status !== 'Cancelled');

    const filledCount = nonCancelledOpenings?.filter((opening: {status: string}) =>
      ['Confirmed', 'Completed', 'PendingCheckIn', 'PendingClockIn', 'PendingReview'].includes(opening.status)
    ).length;

    const completedCount = nonCancelledOpenings?.filter(
      (opening: {status: string}) => opening.status === 'Completed'
    )?.length;

    const totalOpenings = nonCancelledOpenings?.length; // Total openings excluding cancelled ones

    const cancelledOpeningsCount = shift?.openings?.filter(
      (opening: {status: string}) => opening.status === 'Cancelled'
    )?.length;

    const unfilledCount = shift?.openings?.filter((opening: {status: string}) =>
      ['Opened', 'OpenedWithApplicants', 'Selected']?.includes(opening?.status)
    )?.length;

    // applicant count for substatus
    const ndPros = shift?.openings?.map((opening: {ndProId: any}) => opening?.ndProId);
    const filteredApplicantsCount = shift?.applicants
      ?.filter(
        (applicant: {ndProId: any}, index: any, self: any[]) =>
          self.findIndex(t => t.ndProId === applicant.ndProId) === index
      )
      ?.filter((applicant: {ndProId: any}) => !ndPros?.includes(applicant?.ndProId))
      ?.filter((applicant: {status: string}) => applicant?.status === 'Applied')?.length;

    if (isAllCancelled) {
      return {status: 'Cancelled'};
    }

    if (pendingReviewCount > 0) {
      return {
        status: 'Completed-Pending Rating',
        subStatus: `${unfilledCount > 0 ? `${filledCount} of ${totalOpenings} Filled` : ''} ${
          cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
        }`,
      };
    }

    if (completedCount > 0) {
      return {
        status: `Completed`,
        subStatus: `${unfilledCount > 0 ? `${filledCount} of ${totalOpenings} Filled` : ''} ${
          cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
        }`,
      };
    }

    if (allOpenedWithApplicants) {
      return {
        status: `0 of ${totalOpenings} Filled`,
        subStatus: `${filteredApplicantsCount > 0 ? `${filteredApplicantsCount} Applicants` : ''} ${
          cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
        }`,
      };
    }

    return {
      status: `${filledCount} of ${totalOpenings} Filled`,
      subStatus: `${filteredApplicantsCount > 0 ? `${filteredApplicantsCount} Applicants` : ''} ${
        cancelledOpeningsCount > 0 ? `( ${cancelledOpeningsCount} Cancelled )` : ''
      }`,
    };
  }
  return {status: ''};
};

const getFilteredSummary = (shiftData: any, shiftsSummary: UnitPosition[]) => {
  const filteredSummary = [...shiftsSummary]
    ?.map(summary => {
      const shiftsUnitPositions = [...shiftData]?.reduce((acc: any, data: any) => {
        data?.shifts?.forEach((shift: {unitId: any; positionDetail: any[]}) => {
          const existingUnit = acc.find((item: {unitId: any}) => item.unitId === shift.unitId);
          if (existingUnit) {
            // Add position ids to existing unit
            shift.positionDetail.forEach(position => {
              if (!existingUnit.positions.includes(position.id)) {
                existingUnit.positions.push(position.id);
              }
            });
          } else {
            // Create a new unit entry
            acc.push({
              unitId: shift.unitId,
              positions: shift.positionDetail.map(position => position.id),
            });
          }
        });
        return acc;
      }, []);

      const unit: any = shiftsUnitPositions?.find((unitPosition: any) => unitPosition?.unitId === summary?.id);

      return {
        ...summary,
        positions: [...summary.positions]?.filter(e => unit?.positions?.includes(e?.id)),
      };
    })
    .filter(unit => unit.positions.length > 0);

  // if (filteredSummary?.length !== selectedUnitFilter?.length)
  //   handleSelectedUnitFilter(filteredSummary?.map(e => ({unitId: e?.id, positions: e?.positions?.map(x => x?.id)})));

  return filteredSummary;
};

const getShiftCountsByStatus = (shifts: any) =>
  [...shifts]?.reduce(
    (acc, shift) => {
      shift?.openings?.forEach((opening: any) => {
        if (['Opened', 'Selected', 'OpenedWithApplicants'].includes(opening?.status)) {
          acc.unfilledCount += 1;
          if (opening.status === 'OpenedWithApplicants') {
            acc.pendingCount += 1;
          }
        } else if (['PendingReview', 'Completed', 'PendingClockIn', 'PendingCheckIn'].includes(opening?.status)) {
          acc.completedCount += 1;
        } else if (opening?.status === 'Cancelled') {
          acc.cancelledCount += 1;
        } else if (
          ['Confirmed', 'Completed', 'PendingReview', 'PendingClockIn', 'PendingCheckIn'].includes(opening?.status)
        ) {
          acc.filledCount += 1;
        }
      });
      return acc;
    },

    {unfilledCount: 0, pendingCount: 0, filledCount: 0, completedCount: 0, cancelledCount: 0}
  );

const startOfDay = (date: Date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const endOfDay = (date: Date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

const getInviteExpirationDate = (shiftStartTime: string, timeZone : string) => {
  const shiftStartDay = moment(shiftStartTime).tz(timeZone).startOf('day');
  const inviteDay = moment().tz(timeZone).startOf('day');
  const diffDays = shiftStartDay.diff(inviteDay, 'days');

  // CASE 2: Next day invite
  if (diffDays === 1) {
    return moment(inviteDay).tz(timeZone).endOf('day');
  }

  // CASE 3: Within 7 calendar days
  if (diffDays > 1 && diffDays <= 7) {
    return moment(inviteDay).tz(timeZone).add(1, 'day').endOf('day');
  }

  // CASE 4: Next week (beyond 7 days)
  if (diffDays > 7) {
    return moment(inviteDay).tz(timeZone).add(2, 'days').endOf('day');
  }

  return null;
};

export {
  processShifts,
  checkShiftStatus,
  renderShiftStatusText,
  startOfDay,
  endOfDay,
  getFilteredSummary,
  getShiftCountsByStatus,
  getInviteExpirationDate
};
