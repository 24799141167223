import React from 'react';

import {Modal, ModalOverlay, ModalContent, ModalHeader, Text, ModalBody, ModalFooter, Button} from '@chakra-ui/react';
import {LiaUserMinusSolid} from 'react-icons/lia';
import {trackNdproUnselectEvent} from '../../../../shared/mixpanel/events/ndpro';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleUpdateApplicantStatus: (
    id: number,
    applicantName: string,
    select: boolean,
    shiftIds: number[],
    refetchFunction?: any
  ) => void;
  data: {
    id: number;
    name: string;
    shiftIds: number[];
    refetch?: () => void;
  };
};

const ConfirmationModal = ({isOpen, onClose, handleUpdateApplicantStatus, data}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader display="flex" alignItems="center" gap="0.3125rem">
        <LiaUserMinusSolid color="red" />
        <Text fontWeight={700}> Remove from shift</Text>
      </ModalHeader>
      <ModalBody>
        <Text color="#0000007A">Are you sure you want to remove this applicant from the current shift? </Text>
      </ModalBody>

      <ModalFooter display="flex" width="100%" gap="0.5rem">
        <Button variant="secondary" width="100%" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="danger"
          width="100%"
          onClick={() => {
            handleUpdateApplicantStatus(data.id, data.name, false, data.shiftIds, data?.refetch);
            trackNdproUnselectEvent(data, data?.id);
          }}
        >
          Remove
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ConfirmationModal;
