import React from 'react';
import {Flex, Stack, Card, CardHeader, CardBody, Heading, Text, Box, Divider} from '@chakra-ui/react';

import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import uuid from 'react-uuid';

import {Shift} from '../../../routes/Shifts/Shift.types';
import './CalendarDateCard.scss';

type Props = {
  shift: Shift;
  counts: {posted: number; confirmed: number};
  filterCounts?: {posted: number; confirmed: number};
};

const CalendarDateCard = ({shift, counts, filterCounts = {posted: 0, confirmed: 0}}: Props) => {
  const today = moment(new Date()).format('YYYY-MM-DD');

  const newDate2 = moment(shift.date).format('ddd D');

  const [dayOfWeek, dayOfMonth] = newDate2.split(' ');

  const isToday = today === shift.date;

  const todayBorderStyle = isToday ? '2px solid #616CA1' : '1px solid #D8DCE7';

  const {posted, confirmed} = counts || {posted: 0, confirmed: 0};

  const navigate = useNavigate();

  return (
    <Flex key={uuid()}>
      <Card
        w="9.375rem"
        h="4.75rem"
        variant="filled"
        className="cal-date-card"
        background={isToday ? '#DFE2ED' : '#FFFFFF'}
        border={todayBorderStyle}
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
        onClick={() => !moment(shift.date, 'YYYY-MM-DD').isBefore(today) && navigate(`/shifts/create?d=${shift.date}`)}
        cursor="pointer"
        title={!moment(shift.date, 'YYYY-MM-DD').isBefore(today) ? 'Click here to post shift for this day' : undefined}
      >
        <Stack direction={['column']}>
          <Box>
            <CardHeader className="cal-date-card__header" p="0.25rem">
              <Flex justifyContent="space-between" pr="0.375rem" pl="0.375rem">
                <Heading size="sm">{dayOfWeek}</Heading>
                <Heading size="sm">{dayOfMonth}</Heading>
              </Flex>{' '}
            </CardHeader>
          </Box>
          <CardBody p="0.625rem">
            <Divider orientation="horizontal" borderColor="black" />
            <Flex className="cal-date-card__body" justifyContent="space-between" paddingTop="0.25rem">
              <Text fontSize="0.75rem">Posted: </Text>
              <Text fontSize="0.75rem">
                {Number(filterCounts?.posted) !== Number(posted) && <span> {filterCounts?.posted || 0}/</span>}
                {posted}
              </Text>
            </Flex>
            <Flex className="cal-date-card__body" justifyContent="space-between">
              <Text fontSize="0.75rem" fontWeight="600">
                Confirmed:
              </Text>
              <Text fontSize="0.75rem" fontWeight="600">
                {Number(filterCounts?.confirmed) !== Number(confirmed) && <span> {filterCounts?.confirmed || 0}/</span>}
                {confirmed}
              </Text>
            </Flex>
          </CardBody>
        </Stack>
      </Card>
    </Flex>
  );
};

export default CalendarDateCard;
