export const shiftsFields = `
facilityId
    successShiftCreations{
      unitId
      shiftDates{
        startTime
        endTime
        openings
        openingIds
        shiftId
      }
      break
      positionRate
      bonus
      description
      clockInInstruction
      clockOutInstruction
      qualificationIds
    }
    failShiftCreations{
      errorReason
    }
`;

export const editShiftFields = `
    shiftId
    unitId
    startDateTime
    endDateTime
    break
    positionRate
    bonus
    description
    clockInInstruction
    clockOutInstruction
    qualificationIds
`;

export const completedShiftFields = `
    applicantCount
    applicantList {
      id
      applicantId
      nurseId
      name
      relation
      positionType
      credentials
      rating
      photo
      image
      status
      channelId
      reliabilityRating
      reliabilityScore
      total_facility_shifts
      total_shifts
    }
    assigned_nurse_id
    breakTime
    cancelledAt
    checkInCreatedAt
    checkInId
    checkInTime
    checkOutCreatedAt
    checkOutId
    checkOutTime
    createdAt
    deletedAt
    description
    end_time
    facility_id
    hasBreakTime
    id
    net_pay
    qualifications
    rate
    ratingFromNurse
    review {
      overallRating
      customerService
      nurseSkills
      comment
      userUuid
      userName
      hidden
    }
    role
    start_time
    status
    type
    unit
    unitDescription
    updatedAt
    checkInSupervisorName
    checkInSupervisorSignature {
      src
    }
    checkOutSupervisorName
    checkOutSupervisorSignature {
      src
    }
    shiftStatus
    checkInSupervisorEmail
    checkInSupervisorPhone
    checkOutSupervisorEmail
    checkOutSupervisorPhone
`;

export const allNDWorkforcesFields = `
    id
    name
    rating
    email
    reliabilityRating
    reliabilityScore
    totalShiftsWorked
    totalFacilityShiftsWorked
    positionType
    distance
    relationToFacility{
      facilityId
      relation
      reason
      comment
    }
    image {
      src
      data
      title
    }
    inviteShiftValidations {
      isAvailable
      unavailableReason
      isOvertime
      overtimeDuration
    }
    credentials {
      name
      expiration
      type
      expires
      front {
        src
      }
      back {
        src
      }
    }
    resume {
      src
      title
    }
`;
