import MixpanelConstants from '../../constants';
import trackEvent from '../../events/trackEvent';

const trackCalendarMonthlyOverviewPageView = () => trackEvent(MixpanelConstants.CALENDAR_VIEW_MONTHLY_OVERVIEW);
const trackCalendarMonthlyConfirmedPageView = () => trackEvent(MixpanelConstants.CALENDAR_VIEW_MONTHLY_CONFIRMED);
const trackCalendarWeeklyPageView = () => trackEvent(MixpanelConstants.CALENDAR_VIEW_WEEKLY);
const trackCalenderWeeklyPrintPageView = () => trackEvent(MixpanelConstants.CALENDAR_PRINT_VIEW_WEEKLY);

export {
  trackCalendarMonthlyOverviewPageView,
  trackCalendarMonthlyConfirmedPageView,
  trackCalendarWeeklyPageView,
  trackCalenderWeeklyPrintPageView,
};
