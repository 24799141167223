import {
  ModalOverlay,
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleAddOrRemoveFromFavorite: () => void;
  relation: string | null;
};

const AddtoFavroiteModal = ({isOpen, handleClose, handleAddOrRemoveFromFavorite, relation}: Props) => (
    <ChakraModal isOpen={isOpen} onClose={handleClose} size={['s', 'sm']} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap="0.3125rem">
          {/* <MdBlockFlipped color="red" /> */}
          <Text fontWeight={700}>{relation === 'facility-favorite' ? 'Remove from favorite' : 'Add to favorite'}</Text>
        </ModalHeader>

        <ModalBody>
          <Text color="#0000007A">
            Are you sure you want to {relation === 'facility-favorite' ? 'remove' : 'add'} ndpro
            {relation === 'facility-favorite' ? 'from' : 'to'} favorite ?
          </Text>
        </ModalBody>

        <ModalFooter display="flex" width="100%" gap="0.5rem">
          <Button variant="secondary" width="100%" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="danger" width="100%" onClick={handleAddOrRemoveFromFavorite} style={{whiteSpace: 'normal'}}>
            {relation === 'facility-favorite' ? 'Remove from favorite' : 'Add to favorite'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );

export default AddtoFavroiteModal;
