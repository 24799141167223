const commonShiftFields = `
  id
  date
  position
`;

const commonAccessFields = `
  facilityId
  role
  legacyFacilityId
`;

export const shiftUnitFields = `
  facilityId
  testStartDate
  testEndDate
`;

export const shiftsFields = `
  facilityId
  startDate
  endDate
`;

export const getSummaryOfShiftUnitsFields = `
facilityId
unitId
unitName
`;

export const getSearchShiftFields = `
date
shifts {
  shiftId
  unitId
  position
  startTime
  endTime
  bonus
  applicants
  numOfCompleted
  numOfConfirmed
  numOfCancelled
  numOfOpened
  numOfOpenedWithApplicants
  numOfPendingCheckIn
  numOfPendingClockIn
  numOfPendingReview
  numOfNoCallNoShow
  numOfSelected
  openings
  positionDetail
}
`;

export const getShiftDetailsFields = `
  shiftId
  facilityId
  shiftUnitId
  startTime
  endTime
  break
  rate
  bonus
  description
  clockIn
  clockOut
  qualifications
  openingDetails
`;

export const cancelShiftOpeningFields = `
  seqId
  shiftId
  unitId
  startDateTime
  endDateTime
  break
  positionRate
  bonus
  description
  clockInInstruction
  clockOutInstruction
  assignedNurseId
  status
  qualificationIds
  deletedAt
  deletedBy
  refShiftId
  failure
`;

export const createFavoriteFields = `
  facilityId
  ndProId
  relationship
  relationshipScope
  reason
  comment
  createdAt
  createdBy
  updatedAt
  updatedBy
  deletedAt
  deletedBy
`;

export const removeFavoriteFields = `
  facilityId
  ndProId
  relationship
  relationshipScope
  reason
  comment
  createdAt
  createdBy
  updatedAt
  updatedBy
  deletedAt
  deletedBy
`;

export const deleteShiftFields = `
${commonShiftFields}
access {
  ${commonAccessFields}
  facilityName
}
`;

export const updateShiftFields = `
${commonShiftFields}
type
access {
  ${commonAccessFields}
  facilityName
}
`;

export const getCompletedShiftFields = `
applicantCount
applicantList {
  id
  applicantId
  nurseId
  name
  relation
  positionType
  credentials
  rating
  photo
  image
  status
  channelId
}
assigned_nurse_id
breakTime
cancelledAt
checkInCreatedAt
checkInId
checkInTime
checkOutCreatedAt
checkOutId
checkOutTime
createdAt
deletedAt
description
end_time
facility_id
hasBreakTime
id
net_pay
qualifications
rate
ratingFromNurse
review {
  overallRating
  customerService
  nurseSkills
  comment
  userUuid
  userName
  hidden
}
role
start_time
status
type
unit
unitDescription
updatedAt
checkInSupervisorName
checkInSupervisorSignature {
  src
}
checkOutSupervisorName
checkOutSupervisorSignature {
  src
}
shiftStatus

`;

export const updateApplicantStatusFields = `
  breakTime
  channelId
  createdAt
  credentialsNames
  credentials {
    name
    expiration
    type
    expires
    front {
      src
    }
    back {
      src
    }
  }
  deletedAt
  distance
  reliabilityRating
  reliabilityScore
  totalAutoselectPoint
  facilityId
  hasBreakTime
  id
  image
  isOvertime
  name
  nurseId
  orientationMaterialConfirms {
    id
    orientationMaterialUuid
  }
  orientationMaterials {
    src
    uuid
    title
  }
  overtimeDuration
  photo
  relation
  positionType
  rating
  resume {
    src
    title
  }
  shift {
    id
    shiftId
    role
    startTime
    endTime
    status
  }
  shiftId
  status
  summary
  updatedAt
`;

export const getApplicantDataFields = `
  distance
  archiveDate
  id
  name
  nurseId
  facilityId
  totalShifts
  totalFacilityShifts
  relation
  reliabilityRating
  resume {
    src
    title
  }
  image
  channelId
  unitSpecialties
  positionType
  rating
  status
  credentials {
    name
    expiration
    type
    expires
    front {
      src
      title
    }
    back {
      src
      title
    }
  }
`;

export const getAllOverviewShiftsFields = `
  applicantCount
  applicantList {
    id
    applicantId
    archiveDate
    reliabilityRating
    reliabilityScore
    relation
    completedShiftCount
    nurseId
    name
    positionType
    credentials
    rating
    photo
    image
    status
    total_shifts
    total_facility_shifts
    channelId
    isOvertime
    createdAt
    inviteResponseDate
    inviteMessage
    invitedBy
    declinedReason
  }
  bypassAutoSelect
  assigned_nurse_id
  breakTime
  cancelledAt
  createdAt
  deletedAt
  description
  end_time
  facility_id
  id
  net_pay
  prevStatus
  qualifications
  rate
  role
  start_time
  status
  type
  unit
  cancellationPolicyId
  unitDescription
  updatedAt
`;
