import {useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {QueryHookOptions} from '@apollo/react-hooks';
import {getShiftDetailsFields} from '../CalendarShifts/fields';
import {GetNdproWorkForceList, GetShiftInviteWorkforceAvailabilityList, GetSummaryOfShiftDetails} from '../CalendarShifts/types';

import useAppDispatch from '../../hooks/useAppDispatch';
import {useFacilityId} from '../../../routes/Shifts/CreateShift/appSelectors';

import {getShiftDetails, setShiftDetails, getCompletedShift, setGetCompletedShift} from '../../slices/calShiftSlice';

import {useApolloClients} from '../../../core/providers/ApolloContextProvider';
import {allNDWorkforcesFields, completedShiftFields} from './fields';
import {allCompletedShifts} from '../../types';

const getShiftDetail = (fields: string = getShiftDetailsFields) => gql`
    query ($shiftId: String!) {
        getShiftDetail(shiftId: $shiftId){
        ${fields}
        }
    }
    `;

const useGetShift = (options?: QueryHookOptions<GetSummaryOfShiftDetails>, fields?: string) => {
  const {client} = useApolloClients();
  const facilityId = useFacilityId();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getShiftDetails());
  }, [dispatch]);

  return useQuery<GetSummaryOfShiftDetails>(getShiftDetail(fields), {
    skip: !facilityId,
    client,
    ...options,
    onCompleted: data => {
      dispatch(setShiftDetails(data));
    },
    onError: err => {
      console.log('err from useGetShift', err);
    },
  });
};

const getAllCompletedShiftsLegacyQuery = (fields: string = completedShiftFields) => gql`
  query allCompletedShifts($ids: [Int]) {
    allCompletedShifts(filter: {ids: $ids}) {
      ${fields}
    }
  }
`;

const useGetAllCompletedShiftsLegacyQuery = (options?: QueryHookOptions<allCompletedShifts>) => {
  const {legacyClient} = useApolloClients();
  const facilityId = useFacilityId();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCompletedShift());
  }, [dispatch]);

  return useQuery<allCompletedShifts>(getAllCompletedShiftsLegacyQuery(), {
    skip: !facilityId,
    client: legacyClient,
    ...options,
    onCompleted: data => {
      dispatch(setGetCompletedShift(data));
    },
    onError: err => {
      console.log('err from useGetCompletedShift', err);
    },
  });
};

const getAllNdproWorkForceListQuery = (fields: string = allNDWorkforcesFields) => gql`
  query allNDWorkforces($inviteShiftId: Int, $type: String){
    allNDWorkforces(inviteShiftId: $inviteShiftId, type: $type){
      ${fields}
    }
  }
`;

const useGetNdproWorkForceListQuery = (options?: any) => {
  const {legacyClient} = useApolloClients();
  const facilityId = useFacilityId();

  return useQuery<GetNdproWorkForceList>(getAllNdproWorkForceListQuery(), {
    skip: !facilityId,
    client: legacyClient,
    ...options,
    onError: err => {
      console.log('err from useGetCompletedShift', err);
    },
  });
};

const getShiftInviteWorkforceAvailability = (fields: string = allNDWorkforcesFields) => gql`
  query shiftInviteWorkforceAvailability($inviteShiftId: Int){
    shiftInviteWorkforceAvailability(inviteShiftId: $inviteShiftId){
      ${fields}
    }
  }
`;

const useGetShiftInviteWorkforceAvailabilityQuery = (options?: any) => {
  const {legacyClient} = useApolloClients();
  const facilityId = useFacilityId();

  return useQuery<GetShiftInviteWorkforceAvailabilityList>(getShiftInviteWorkforceAvailability(), {
    skip: !facilityId,
    client: legacyClient,
    ...options,
    onError: err => {
      console.log('err from useGetShiftInviteWorkforceAvailabilityQuery', err);
    },
  });
};

export {
  useGetShift,
  useGetAllCompletedShiftsLegacyQuery,
  useGetNdproWorkForceListQuery,
  useGetShiftInviteWorkforceAvailabilityQuery,
};
