import React from 'react';

import {Link as RouterLink} from 'react-router-dom';
import {FlexProps, Flex, Icon, Badge} from '@chakra-ui/react';
import {IconType} from 'react-icons';

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: any;
  url?: string;
  reloadDocument?: boolean;
  badge?: number;
  target?:string;
}

const NavItem = ({icon, url = '', children, reloadDocument, badge,target, ...rest}: NavItemProps) => {
  const width = badge && badge < 10 ? '5' : 'auto';
  const badgeText = badge && badge > 0 ? badge : '';
  return (
    <RouterLink to={url} reloadDocument={reloadDocument} target={target || '_self'}>
      <Flex
        align="center"
        p="4"
        margin="0 10px"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="white"
        _hover={{
          bg: 'gray.700',
        }}
        _active={{
          bg: '#475392',
        }}
        {...rest}
      >
        <Flex
          _hover={{
            textDecoration: 'underline',
          }}
          align="center"
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
        {badgeText && (
          <Badge
            ml="0"
            mt="-3.5"
            colorScheme="red"
            variant="solid"
            width={width}
            height="5"
            borderRadius="full"
            fontSize="0.8em"
            textAlign="center"
          >
            {badgeText}
          </Badge>
        )}
      </Flex>
    </RouterLink>
  );
};

export default NavItem;
