import trackEvent from './trackEvent';
import MixpanelConstants from '../constants';
import {SingleValue} from '../../../routes/ShiftTemplates/ShiftTemplates.types';

const trackCreateTemplateEvent = (data: Record<string, any>) =>
  trackEvent(MixpanelConstants.CREATE_SHIFT_TEMPLATE, {data}); // didn't add because it's a auth0 page and event
const trackUpdateTemplateEvent = (shiftTemplateId: string, data: Record<string, any>) =>
  trackEvent(MixpanelConstants.UPDATE_SHIFT_TEMPLATE, {shiftTemplateId, data});
const trackDeleteTemplateEvent = (shiftTemplateId: string, data: Record<string, any>) =>
  trackEvent(MixpanelConstants.DELETE_SHIFT_TEMPLATE, {shiftTemplateId, data});

const trackShiftTemplateSearchFilter = (filter: string) =>
  trackEvent(MixpanelConstants.SHIFT_TEMPLATE_SEARCH_FILTER, {filter});
const trackShiftTemplatePositionFilter = (filter: SingleValue) =>
  trackEvent(MixpanelConstants.SHIFT_TEMPLATE_POSITION_FILTER, {filter});
const trackShiftTemplateUnitFilter = (filter: SingleValue) =>
  trackEvent(MixpanelConstants.SHIFT_TEMPLATE_UNIT_FILTER, {filter});

export {
  trackCreateTemplateEvent,
  trackUpdateTemplateEvent,
  trackDeleteTemplateEvent,
  trackShiftTemplateSearchFilter,
  trackShiftTemplatePositionFilter,
  trackShiftTemplateUnitFilter,
};
