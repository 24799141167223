import trackForm from './trackEvent';
import MixpanelConstants from '../constants';
import {ShiftCreationData} from '../../gql/shifts/types';

const trackCreateShiftEvent = (metadata: ShiftCreationData, shiftId?: string) =>
  trackForm(MixpanelConstants.POST_SHIFT, {shiftId, ...metadata});
const trackUpdateShiftEvent = (metadata: Record<string, any>, shiftId?: string) =>
  trackForm(MixpanelConstants.EDIT_SHIFT, {shiftId, ...metadata});
const trackCancelShiftEvent = (metadata: Record<string, any>, shiftId?: string) =>
  trackForm(MixpanelConstants.CANCEL_SHIFT, {shiftId, ...metadata});

// shift actions
// const trackMessageShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_MESSAGE, {shiftId, metadata, applicantId});
// const trackViewProfileShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_NDPRO_PROFILE, {shiftId, metadata, applicantId});
// const trackEditShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_EDIT, {shiftId, metadata, applicantId});
// const trackCancelShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_CANCEL, {shiftId, metadata, applicantId});
// const trackAddNdproFavShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_ADD_FAVORITE, {shiftId, metadata, applicantId});
// const trackRemoveNdproFavShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_REMOVE_FAVORITE, {shiftId, metadata, applicantId});
// const trackAddNdproDNRShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_ADD_DNR, {shiftId, metadata, applicantId});
// const trackRemoveNdproDNRShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_REMOVE_DNR, {shiftId, metadata, applicantId});
// const trackUnselectNdproDNRShiftActionEvent = (metadata: Record<string, any>, shiftId?: string, applicantId?: number) =>
//   trackForm(MixpanelConstants.SHIFT_ACTIONS_UNSELECT, {shiftId, metadata, applicantId});

// shift applicant actions

export {trackCreateShiftEvent, trackUpdateShiftEvent, trackCancelShiftEvent};
