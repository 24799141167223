import React, {useState, useEffect} from 'react';
import {Box, Button, Flex, Text, useDisclosure, useToast} from '@chakra-ui/react';
import uuid from 'react-uuid';

import moment from 'moment';
import {IoMdAddCircle} from 'react-icons/io';

import {useNavigate} from 'react-router-dom';
import CalendarDateCard from './CalendarDateCard';
import CalendarShiftCard from './CalendarShiftCard';
import {Shift, UnitPosition} from '../../../routes/Shifts/Shift.types';
import {useSessionUser, useTimeZone} from '../../../routes/Shifts/CreateShift/appSelectors';
import {checkShiftStatus} from '../../../routes/Shifts/CalendarPage/helpers/helpers';
import RateNDProModal from '../../../routes/Shifts/CalendarPage/RateNDProModal';
import {useRemoveRelationToFacility, useSetRelationToFacility} from '../../gql/CalendarShifts/mutations';
import {trackNdproFavEvent, trackNdproRemoveFavEvent} from '../../mixpanel/events/ndpro';

type Props = {
  startDate: Date;
  endDate: Date;
  selectedUnitFilter: {unitId: string; positions: string[]}[];
  shifts: Shift[];
  searchAllShifts: () => Promise<void>;
  units: UnitPosition[];
  statusFilter: string[];
};

const CalendarTable = ({
  startDate,
  endDate,
  selectedUnitFilter,
  shifts,
  searchAllShifts,
  units,
  statusFilter,
}: Props) => {
  const [showCard, setShowCard] = useState(false);
  const [adjustedShifts, setAdjustedShifts] = useState<Shift[]>([]);
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [rateNdProModalDetails, setRateNdProModalDetails] = useState<any>(null);
  const {isOpen: isRateNDProModalOpen, onOpen: onRateNDProModalOpen, onClose: onRateNDProModalClose} = useDisclosure();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();

  const sessionUserTimeZone = useTimeZone();

  const adjustDateToLocal = (utcDate: string, localTimeZone: string = 'UTC') => {
    const facilityTime = moment.utc(utcDate).tz(localTimeZone);
    return facilityTime.format('YYYY-MM-DD HH:mm:ss');
  };

  const newStartDate = moment(startDate).format('YYYY-MM-DD');
  const newEndDate = moment(endDate).format('YYYY-MM-DD');

  const datesOfWeek = Array.from({length: 7}, (_, i) => moment(startDate).add(i, 'days').format('YYYY-MM-DD'));

  const filterDates = shifts.map(x => x.date).reverse();

  const unitDates = new Map();

  const filteredShifts = [...adjustedShifts]?.filter(shift => {
    const shiftDate = moment(shift?.shifts[0]?.startTime).startOf('day');

    const aStartDate = moment(newStartDate);
    const aEndDate = moment(newEndDate);

    const isWithinDateRange = shiftDate.isSameOrAfter(aStartDate, 'day') && shiftDate.isSameOrBefore(aEndDate, 'day');

    const hasSelectedUnit = shift.shifts.some(innerShift =>
      selectedUnitFilter?.find(unit => unit.unitId === innerShift.unitId)
    );

    return isWithinDateRange && hasSelectedUnit;
  });

  const processInnerShift = (
    shift: any,
    innerShift: any,
    innerShiftTimeZone: string | null,
    innerShiftUnitDates: Map<string, any[]>
  ) => {
    if (!innerShiftTimeZone) {
      console.error('Timezone is undefined');
      return;
    }

    const shiftDateLocal = shift.date;
    const formattedStartTime = moment(innerShift.startTime).format('hh:mm:ss A');

    const existingShifts = innerShiftUnitDates.get(shiftDateLocal) || [];

    const doesShiftExist = existingShifts.some(existingShift =>
      existingShift.shifts.some((exShift: any) => exShift.startTime === innerShift.startTime)
    );

    if (!doesShiftExist) {
      existingShifts.push({
        ...shift,
        time: formattedStartTime,
      });
    }

    if (!unitDates.has(shiftDateLocal)) {
      innerShiftUnitDates.set(shiftDateLocal, existingShifts);
    }
  };

  filteredShifts.forEach(shift => {
    shift.shifts.forEach(innerShift => {
      processInnerShift(shift, innerShift, timeZone, unitDates);
    });
  });

  useEffect(() => {
    setTimeZone(sessionUserTimeZone || null);
  }, [sessionUserTimeZone]);

  useEffect(() => {
    if (filterDates) {
      setShowCard(true);
    } else {
      setShowCard(false);
    }
  }, [filterDates, newStartDate, newEndDate, shifts]);

  useEffect(() => {
    // Existing filtering logic here for specific units
    const newFilteredUnits = shifts.filter(shift =>
      shift.shifts.some(
        innerShift =>
          selectedUnitFilter?.find(unit => unit.unitId === innerShift.unitId) &&
          selectedUnitFilter
            ?.find(unit => unit.unitId === innerShift.unitId)
            ?.positions?.includes(innerShift?.positionDetail?.[0]?.name)
      )
    );

    const statusFilteredShifts = newFilteredUnits.map(shift => ({
      ...shift,
      shifts: shift.shifts
        .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, timeZone || 'UTC')))
        .map(innerShift => ({
          ...innerShift,
          startTime: adjustDateToLocal(innerShift.startTime, timeZone || 'UTC'),
          endTime: adjustDateToLocal(innerShift.endTime, timeZone || 'UTC'),
        })),
    }));

    setAdjustedShifts(statusFilteredShifts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shifts, selectedUnitFilter, statusFilter]);

  useEffect(() => {
    if (timeZone) {
      const localShifts = shifts.map(shift => ({
        ...shift,
        date: shift.date,
        shifts: shift.shifts
          .filter(innerShift => statusFilter.includes(checkShiftStatus(innerShift, timeZone || 'UTC')))
          .map(innerShift => ({
            ...innerShift,
            startTime: adjustDateToLocal(innerShift.startTime, timeZone),
            endTime: adjustDateToLocal(innerShift.endTime, timeZone),
          })),
      }));

      if (localShifts.length > 0) {
        const reversedLocalShifts = [...localShifts].reverse();
        setAdjustedShifts(reversedLocalShifts);
      }
    }
  }, [shifts, statusFilter, timeZone]);

  const [ids, setIds] = useState<number[]>([]);

  const handleRateNDProClick = (id: number, shiftData?: any) => {
    setIds([id]);

    const newDate = moment
      .utc(shiftData.startTime ?? '')
      .tz(timeZone ?? '')
      .format('dddd MMMM Do');

    const formatShiftTime = (time: Date, formattingTimeZone?: string) =>
      formattingTimeZone ? moment.utc(time).tz(formattingTimeZone).format('h:mm A') : moment.utc(time).format('h:mm A');

    const convertedStartTime = shiftData ? formatShiftTime(new Date(shiftData.startTime), timeZone || 'UTC') : '';
    const convertedEndTime = shiftData ? formatShiftTime(new Date(shiftData.endTime), timeZone || 'UTC') : '';

    const modalDetails = {
      unitName: shiftData?.selectedUnit,
      position: shiftData?.position,
      date: newDate,
      startTime: convertedStartTime,
      endTime: convertedEndTime,
      shiftBreak: shiftData?.break,
      timeZone,
      isCompleted: false,
      id,
    };
    if (sessionUser?.role !== 'Viewer') {
      setRateNdProModalDetails(modalDetails);
      onRateNDProModalOpen();
    }
  };

  const renderShiftCards = (date: string, isToday: boolean) => {
    if (!showCard) {
      return <Flex key={uuid()} width="8.375rem" />;
    }

    // Logic for "All" units selected
    const shiftsToRender = Array.from(unitDates.values())
      // Logic for rendering shifts when specific unit is selected
      .flatMap(newShifts =>
        newShifts.flatMap((shift: any) => {
          const filteredInnerShifts = shift.shifts
            ?.filter(
              (innerShift: any) =>
                selectedUnitFilter?.find(unit => unit.unitId === innerShift.unitId) &&
                selectedUnitFilter
                  ?.find(unit => unit.unitId === innerShift.unitId)
                  ?.positions?.includes(innerShift?.positionDetail?.[0]?.name)
            )
            ?.filter((innerShift: any) => {
              const shiftDate = moment(innerShift.startTime).format('YYYY-MM-DD');
              return shiftDate === date && statusFilter.includes(checkShiftStatus(innerShift, timeZone || 'UTC'));
            });

          if (filteredInnerShifts.length === 0) {
            return null;
          }

          return {
            ...shift,
            shifts: filteredInnerShifts,
          };
        })
      )
      .filter(Boolean)
      ?.flatMap((shift: any) =>
        shift.shifts.flatMap((innerShift: any) => {
          const selectedUnit = units.find(unit => unit.id === innerShift.unitId)?.name || '';
          return {
            ...innerShift,
            date: shift.date,
            shifts: shift.shifts,
            position: innerShift.position,
            startTime: innerShift.startTime,
            endTime: innerShift.endTime,
            selectedUnit,
          };
        })
      )
      ?.sort((a, b) => {
        // Convert date strings to Date objects
        const dateA = new Date(a?.startTime);
        const dateB = new Date(b?.startTime);

        // Compare the Date objects
        return Number(dateA) - Number(dateB);
      })
      .filter(Boolean);

    const isFutureShift = moment().isBefore(moment(date));
    // Render shift cards or show no shifts message
    if (shiftsToRender.length === 0) {
      if (isFutureShift && sessionUser?.role !== 'Viewer') {
        return (
          <Flex key={uuid()} w="9.375rem" p=".125rem" borderRadius="0.3125rem" bg="#eaf0f7" mt="0.3125rem">
            <Flex
              justifyContent="center"
              alignItems="center"
              color="gray.700"
              fontWeight="600"
              minH="3.125rem"
              width="100%"
            >
              <Button
                color="#405FF2"
                background="white"
                _hover={{background: 'white'}}
                onClick={() => navigate(`/shifts/create?d=${date}`)}
              >
                <IoMdAddCircle /> Post Shift
              </Button>
            </Flex>
          </Flex>
        );
      }
      return (
        <Flex key={uuid()} w="9.375rem" p=".125rem" borderRadius="0.3125rem" bg="#eaf0f7" mt="0.3125rem">
          <Flex
            justifyContent="center"
            alignItems="center"
            color="gray.700"
            fontWeight="600"
            minH="3.125rem"
            width="100%"
          >
            <Text className="no-shift-card" fontSize="0.6875rem">
              No Shifts To Display
            </Text>
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex key={uuid()} w="9.375rem" mt="0.3125rem" background={isToday ? '#E8EAF1' : '#F9FBFF'}>
        <Box key={uuid()} p=".125rem" gap="0.1rem" borderRadius="0.3125rem">
          {shiftsToRender.map((shift: any) => (
            <CalendarShiftCard
              key={uuid()}
              shift={shift}
              unitName={shift?.selectedUnit}
              startDate={startDate}
              endDate={endDate}
              searchAllShifts={searchAllShifts}
              handleRateNDProClick={handleRateNDProClick}
            />
          ))}
        </Box>
      </Flex>
    );
  };

  const adjustedShiftsBasedOnTime = shifts.map(shift => ({
    ...shift,
    shifts: shift?.shifts?.map(innerShift => ({
      ...innerShift,
      startTime: adjustDateToLocal(innerShift.startTime, timeZone || 'UTC'),
      endTime: adjustDateToLocal(innerShift.endTime, timeZone || 'UTC'),
    })),
  }));

  const calculateCounts = (shiftsArray: any[]) =>
    shiftsArray.reduce<{[key: string]: {posted: number; confirmed: number}}>((acc, shift) => {
      shift?.shifts?.forEach((innerShift: {startTime: string; posted: number; confirmed: number}) => {
        const date = innerShift.startTime.split(' ')[0];
        if (!acc[date]) acc[date] = {posted: 0, confirmed: 0};
        acc[date].posted += innerShift.posted || 0;
        acc[date].confirmed += innerShift.confirmed || 0;
      });
      return acc;
    }, {});

  const counts = calculateCounts(adjustedShiftsBasedOnTime);

  const filterShiftsCounts = calculateCounts(
    adjustedShiftsBasedOnTime
      .map(shift => {
        const filteredShiftsList = shift.shifts?.filter(innerShift => {
          const unitMatch = selectedUnitFilter?.find(unit => unit.unitId === innerShift.unitId);
          const positionMatch = unitMatch?.positions?.includes(innerShift?.positionDetail?.[0]?.name);
          const statusMatch = statusFilter.includes(checkShiftStatus(innerShift, timeZone || 'UTC'));
          return unitMatch && positionMatch && statusMatch;
        });
        return filteredShiftsList?.length ? {...shift, shifts: filteredShiftsList} : null;
      })
      .filter(Boolean) as any[] // Remove nulls
  );
  
  const renderDateCards = () =>
    datesOfWeek.map(date => {
      const aShifts = unitDates.get(date) || [];
      const dummyShift = {
        date,
        posted: 0,
        confirmed: 0,
        shifts: [],
      };
      const shiftData =
        aShifts.length > 0 ? {...aShifts[0], shifts: aShifts.flatMap((shift: any) => shift.shifts)} : dummyShift;

      const today = moment(new Date()).format('YYYY-MM-DD');

      const isToday = today === shiftData.date;

      return (
        <Box
          className="date-card-stack"
          key={uuid()}
          p="0.1875rem"
          mt="0.3125rem"
          borderRadius="0.3125rem"
          background={isToday ? '#E8EAF1' : '#F9FBFF'}
        >
          <CalendarDateCard
            key={uuid()}
            shift={shiftData}
            counts={counts[shiftData.date]}
            filterCounts={filterShiftsCounts[shiftData.date]}
          />
          {renderShiftCards(date, isToday)}
        </Box>
      );
    });

  const [setRelationToFacility] = useSetRelationToFacility();
  const [removeRelationToFacility] = useRemoveRelationToFacility();
  const toast = useToast();
  const displayToast = (description: string, toastStatus: 'loading' | 'error' | 'info' | 'warning' | 'success') => {
    toast({
      title: description,
      status: toastStatus,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleLegacyFavorite = async (
    nurseId: number,
    relation: string,
    name: string,
    refetch?: () => void,
    data?: any
  ) => {
    if (relation === 'facility-favorite') {
      try {
        const setRelationResponse = await removeRelationToFacility({
          variables: {
            nurseId,
            reason: '',
            relation: 'facility-favorite',
          },
        });
        if (setRelationResponse.data) {
          displayToast(`${name} has been removed from your Favorites List`, 'error');
          trackNdproRemoveFavEvent(data, nurseId);
          if (refetch) refetch();
        } else if (!setRelationResponse.data) {
          displayToast('Remove applicant from favourite failed!', 'error');
        }
      } catch (error) {
        console.log('error:', error);
      }
    } else {
      try {
        const setRelationResponse = await setRelationToFacility({
          variables: {
            nurseId,
            reason: '',
            relation: 'facility-favorite',
          },
        });
        if (setRelationResponse.data) {
          displayToast(`${name} has been added to your Favorites List`, 'success');
          trackNdproFavEvent(data, nurseId);
          if (refetch) refetch();
        } else if (!setRelationResponse.data) {
          displayToast('Add applicant to favourite failed!', 'error');
        }
      } catch (error) {
        console.log('error:', error);
      }
    }
  };

  return (
    <Flex className="table-card-container" flexDirection="column" width="100%" height="100%">
      <Flex className="date-container" flex="1">
        {renderDateCards()}
      </Flex>
      {rateNdProModalDetails && (
        <RateNDProModal
          isOpen={isRateNDProModalOpen}
          onClose={onRateNDProModalClose}
          modalData={rateNdProModalDetails}
          refetchShiftOpenings={searchAllShifts}
          handleLegacyFavorite={handleLegacyFavorite}
          ids={ids}
        />
      )}
    </Flex>
  );
};

export default CalendarTable;
